<template>
  <div class="home page-container">
    <div class="banner overflow-hidden text-white text-wrap">
      <h1 class="greeting">Hi<br />I'm Lei</h1>
      <p class="p1 fw-3">An experienced Product designer passionate about crafting seamless journeys from concept to reality, with a strong focus on delivering impactful B2B product designs.</p>
      <a class="hover-link d-inline-flex align-items-center text-white text-decoration-none fw-5" href="mailto:kelei1992@outlook.com" target="_blank">
        Let's get in touch <img loading="lazy" class="social-icon" :src="hashUrl('arrow.svg')"
      /></a>
    </div>
    <div class="w-100 overflow-hidden clear-float">
      <router-link
        class="project-card float-left d-block text-decoration-none"
        v-for="(item, index) in MY_PROJECTS"
        :key="index"
        :to="item.link"
      >
        <div class="cover w-100 overflow-hidden">
          <video v-if="item.coverVideo" class="w-100 d-block cover-img" autoplay playsinline muted preload="auto" loop :src="item.coverVideo"></video>
          <img v-else loading="lazy" class="w-100 d-block cover-img" :src="item.cover" />
          <div class="learn-more d-flex text-white fw-5">View project <img loading="lazy" :src="hashUrl('arrow.svg')" /></div>
        </div>
        <p class="d-flex align-items-center text-white project-name">
          <img loading="lazy" class="project-logo" :src="item.logo" />
          <span class="flex-1 pl-3 fw-7 fs-1 hover-opacity-8" v-text="item.name"></span>
          <span class="pl-5 text-light fs-0" v-text="item.desc"></span>
        </p>
      </router-link>
    </div>
  </div>
</template>
  
  <script>
import { MY_PROJECTS } from "@/constant";
export default {
  name: "HomePage",
  components: {  },
  data() {
    return {
      MY_PROJECTS,
    };
  },
};
</script>
  
<style lang="scss">
@import "../style/home.scss";
</style>
  